import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const CurrencyContext = createContext();

export const useCurrency = () => {
  return useContext(CurrencyContext);
};

export const CurrencyProvider = ({ children }) => {
  const [selectedCurrency, setSelectedCurrency] = useState('SGD');
  const [currencyRates, setCurrencyRates] = useState('SGD');

  useEffect(() => {
    fetchInitialExchangeRates();
  }, []);

  const fetchInitialExchangeRates = async () => {
    try {
      const response = await axios.get("https://api.exchangerate-api.com/v4/latest/SGD");
      const ratesData = response.data.rates;

      const allCurrencies = Object.entries(ratesData).reduce((acc, [currencyCode, rate]) => {
        acc[currencyCode] = { symbol: currencyCode, rate };
        return acc;
      }, {});

      setCurrencyRates(allCurrencies);
    } catch (error) {
      console.error('Error fetching initial exchange rates:', error.message);
    }
  };

  return (
    <CurrencyContext.Provider value={{ selectedCurrency, setSelectedCurrency, currencyRates }}>
      {children}
    </CurrencyContext.Provider>
  );
};