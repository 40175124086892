import { 
	FETCH_PRODUCTS_SUCCESS, 
	ADD_TO_SELL_SIZES,
	REMOVE_All_SELL_SIZES,
	REMOVE_TO_SELL_SIZES,
	ADD_TO_BUYER_SIZES,
	REMOVE_TO_BUYER_SIZES,
	ADD_RECENT_PRODUCT,
	FETCH_PRODUCT_TAGS,
	FETCH_PRODUCT_BANNERS,
	FETCH_PRODUCT_DEMANDS,
	FETCH_PRODUCT_BRANDS
} from "../actions/productActions";

const initState = {
  products: []
};

const initStateBuyerSize = {
  cartItem: {}
};

const brands = [];
const demads = [];
const tags = [];
const banners = [];

const productReducer = (state = initState, action) => {
	if (action.type === FETCH_PRODUCTS_SUCCESS) {
		return {
		  ...state,
		  products: action.payload
		};
	}
  return state;
};

export default productReducer;

export const brandReducer = (state = brands, action) => {
	if (action.type === FETCH_PRODUCT_BRANDS) {
		return action.payload;
	}
	return state;
};

export const demandReducer = (state = demads, action) => {
	if (action.type === FETCH_PRODUCT_DEMANDS) {
		return action.payload;
	}
	return state;
};

export const tagReducer = (state = tags, action) => {
	if (action.type === FETCH_PRODUCT_TAGS) {
		return action.payload;
	}
	return state;
};

export const bannerReducer = (state = banners, action) => {
	if (action.type === FETCH_PRODUCT_BANNERS) {
		return action.payload;
	}
	return state;
};

export const SizesReducer = (state = initState, action) => {
	var addSizes = [];
	if( action.type === ADD_TO_SELL_SIZES ){
		addSizes = [];
		
		/*if( typeof state.addSizes !== 'undefined' ){
			addSizes = state.addSizes;
		}*/
		addSizes.push(action.payload);
		return {
			addSizes
		};
	}
	else if( action.type ===REMOVE_All_SELL_SIZES ){
		addSizes = [];
		return {
			addSizes
		};
	}
	else if( action.type ===REMOVE_TO_SELL_SIZES ){
		var all_data = state.addSizes;
		addSizes = ((all_data,action)=>
			all_data.filter(addSize => addSize.size_id !== action.payload.size_id)
		);
		return {addSizes:addSizes(all_data,action)};
	}

	return state;
};

export const AddSizeBuyerReducer = (state = initStateBuyerSize, action) => {
	
	if( action.type === ADD_TO_BUYER_SIZES ){
		return action.payload;
	}
	else if( action.type === REMOVE_TO_BUYER_SIZES ){
		return {};
	}
	return state;
};

export const RecentViewReducer = (state = [], action) => {
	
	if( action.type === ADD_RECENT_PRODUCT ){
		
		var all_data = [];
		
		if( state.length>0 ){
			all_data = state;
		}
		all_data.push(action.payload);
		return all_data;
	}
	
	return state;
};

