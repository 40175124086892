export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCT_BRANDS = "FETCH_PRODUCT_BRANDS";
export const FETCH_PRODUCT_TAGS = "FETCH_PRODUCT_TAGS";
export const FETCH_PRODUCT_BANNERS = "FETCH_PRODUCT_BANNERS";
export const ADD_TO_SELL_SIZES = "ADD_TO_SELL_SIZES";
export const REMOVE_All_SELL_SIZES = "REMOVE_All_SELL_SIZES";
export const REMOVE_TO_SELL_SIZES = "REMOVE_TO_SELL_SIZES";
export const ADD_TO_BUYER_SIZES = "ADD_TO_BUYER_SIZES";
export const ADD_RECENT_PRODUCT = "ADD_RECENT_PRODUCT";
export const REMOVE_TO_BUYER_SIZES = "REMOVE_TO_BUYER_SIZES";
export const FETCH_PRODUCT_DEMANDS = "FETCH_PRODUCT_DEMANDS";

const fetchProductsSuccess = products => ({
  type: FETCH_PRODUCTS_SUCCESS,
  payload: products
});

// fetch products
export const fetchProducts = products => {
  return dispatch => {
    dispatch(fetchProductsSuccess(products));
  };
};

export const addToSizes = data => {
	var action_status;
	if( data.checked ){
		action_status = ADD_TO_SELL_SIZES;
	}
	else{
		action_status = REMOVE_TO_SELL_SIZES;
	}
	return {
	  type: action_status,
	  payload: data
	}
};

export const addToBrandlist = data => {
	return {
	  type: FETCH_PRODUCT_BRANDS,
	  payload: data
	}
};

export const addToDemandlist = data => {
	return {
	  type: FETCH_PRODUCT_DEMANDS,
	  payload: data
	}
};

export const addToTaglist = data => {
	return {
	  type: FETCH_PRODUCT_TAGS,
	  payload: data
	}
};

export const addToBannerlist = data => {
	return {
	  type: FETCH_PRODUCT_BANNERS,
	  payload: data
	}
};

export const removeToSizes = () => {
	return {
	  type: REMOVE_All_SELL_SIZES
	}
};

export const addSizeCartData = item => {
	
	return {
	  type: ADD_TO_BUYER_SIZES,
	  payload: item
	}
};

export const addRecentProductData = item => {
	
	return {
	  type: ADD_RECENT_PRODUCT,
	  payload: item
	}
};

export const removeSizeCartData = () => {
	
	return {
	  type: REMOVE_TO_BUYER_SIZES
	}
};