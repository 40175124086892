import {API_URL} from "../../global";

export default function ProductList(){
	var data = [];
	async function fetchData(){
		let result = await fetch(API_URL+'/api/product-list');
		result = await result.json();
		return result;
	}
	data = fetchData();
	return data;
}
