import React, { useEffect } from "react";
import { useHistory} from "react-router-dom";

const Protected = (prop) => { 
	const history = useHistory();
	let Component = prop.component;
	let path = prop.path;
	
	useEffect(()=>{
		
		if(!localStorage.getItem('user-info') ){
			history.push("/");
		}
	},[history]);
	
  return (
	<>
	<Component location={path} />
	</>
  );
};

export default Protected;
