import currencyReducer from "./currencyReducer";
import productReducer,{SizesReducer,AddSizeBuyerReducer,RecentViewReducer,brandReducer,tagReducer,bannerReducer,demandReducer} from "./productReducer";
import cartReducer from "./cartReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  sizeData: SizesReducer,
  brandData: brandReducer,
  demandData: demandReducer,
  tagData: tagReducer,
  bannerData: bannerReducer,
  sizeBuyerData:AddSizeBuyerReducer,
  recentProducts:RecentViewReducer,
  cartData: cartReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer
});

export default rootReducer;
