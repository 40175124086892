import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  REMOVE_ALL_FROM_WISHLIST,
  FETCH_TO_WISHLIST
} from "../actions/wishlistActions";
import axios from "axios";
import {API_URL} from "../../global";

const initState = [];

const wishlistReducer = (state = initState, action) => {
  const wishlistItems = state,product = action.payload;
	
  switch (action.type) {
    case ADD_TO_WISHLIST:
		if(localStorage.getItem('user-info')){
			SetWishlistVal( ADD_TO_WISHLIST, product.id );
		}
	
		const wishlistItem = wishlistItems.filter(
			item => item.id === product.id
		)[0];
		if (wishlistItem === undefined) {
			return [...wishlistItems, product];
		} 
		else {
			return wishlistItems;
		}
	
	case FETCH_TO_WISHLIST:
		return product;

	case REMOVE_FROM_WISHLIST:
		if(localStorage.getItem('user-info')){
			SetWishlistVal( REMOVE_FROM_WISHLIST, product.id );
		}
		const remainingItems = (wishlistItems, product) =>
        wishlistItems.filter(wishlistItem => wishlistItem.id !== product.id);
		return remainingItems(wishlistItems, product);

    case REMOVE_ALL_FROM_WISHLIST:
		if(localStorage.getItem('user-info')){
			SetWishlistVal( REMOVE_ALL_FROM_WISHLIST, 0 );
		}
		return wishlistItems.filter(item => {
			return false;
		});

    default:
      return wishlistItems;
  }
};

export default wishlistReducer;


async function SetWishlistVal( type, product_id ){
	const item = new FormData();
	var user = JSON.parse(localStorage.getItem('user-info'));
	item.append('user', user.user_id);
	item.append('type', type);
	item.append('product_id', product_id);
	
	axios({
		method: "post",
		url: API_URL+'/api/set-wishlist-buyer',
		data: item,
		processData: false,
		contentType: false,
	})
	.then(res => {
		//code
	});
}


